/**=====================
     39. Table  CSS Start
==========================**/
.pills-component {
	width: 20%;
}

.product-page-width {
	width: 80%;
}

.table {
	margin-bottom: $table-b-margin;

	th,
	td {
		padding: $table-padding;
	}

	th {
		color: $table-heading-color;
		background-color: $table-thead;
		font-family: $font-roboto;
		font-weight: 500;
	}

	td {
		color: $table-row-color;
		font-family: $font-roboto;
		font-weight: 200;
		text-align: center;
	}

	[class*='bg-'] {
		color: $white;
	}

	.bg-light {
		color: $theme-body-font-color;
	}

	tfoot {
		font-weight: $table-footer-font-weight;
	}

	tbody {
		border: 1px solid $table-border-color;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
	}
}

.table-inverse {

	th,
	td {
		color: $white;
	}
}

.table[class*='bg-'] {

	th,
	td {
		color: $white;
	}
}

.table-hover {
	tbody {
		tr:hover {
			background-color: $table-hover-color;
		}
	}
}

//Sizing class
.table-xl {

	td,
	th {
		padding: $table-xl-padding;
		font-size: $table-xl-font;
	}
}

.table-lg {

	td,
	th {
		padding: $table-lg-padding;
		font-size: $table-lg-font;
	}
}

.table-de {

	td,
	th {
		padding: $table-de-padding;
	}
}

.table-sm {

	th,
	td {
		padding: $table-sm-padding;
		font-size: $table-sm-font;
	}
}

.table-xs {

	th,
	td {
		padding: $table-xs-padding;
		font-size: $table-xs-font;
	}
}

//Table Border
.table-border-horizontal {

	tr,
	th,
	td {
		border-top: 1px solid $horizontal-border-color;
		padding: $horizontal-padding;
	}
}

.table-border-vertical {

	tr,
	th,
	td {
		border-top: 0;
		border-right: 1px solid $horizontal-border-color;
	}

	tr {
		td {
			&:last-child {
				border-right: none;
			}
		}
	}
}

.table-bordernone {
	td {
		border: 0;
	}

	.bd-t-none {
		border-top: none;
	}

	.u-s-tb {
		padding: 11px;
	}
}

.table-inverse {
	color: $white;
	background-color: $table-inverse-bg-color;

	th,
	td {
		border-color: $white;
	}

	&.table-bordered {
		border: 0;
	}
}

.table-striped {
	tbody {
		tr {
			&:nth-of-type(odd) {
				background-color: rgba(245, 247, 250, 0.5);
			}
		}
	}
}

.table-bordered {

	td,
	th {
		border-color: $light;
	}
}

/**=====================
     39.Table CSS Ends
==========================**/
#oce {
	table-layout: fixed;
	width: 1558px;

	th {
		&:nth-child(1) {
			width: 160px;
		}

		&:nth-child(2) {
			width: 205px;
		}

		&:nth-child(3) {
			width: 190px;
		}

		&:nth-child(4) {
			width: 230px;
		}

		&:nth-child(5),
		&:nth-child(6),
		&:nth-child(7) {
			width: 227px;
		}
	}
}

.fix-lay {
	table-layout: fixed;
}

th:nth-child(1) {
	/* width: 12%; */
	border-top-left-radius: 20px;
}

/* th:nth-child(2) {
	width: 12%;
} */

/* th:nth-child(3) {
	width: 14%;
} */

/* th:nth-child(4) {
	width: 16%;
} */

/* th:nth-child(4) {
	width: 12%;
} */

/* th:nth-child(5) {
	width: 18%;
} */

/* th:nth-child(6) {
	width: 18%;
} */

th:last-child {
	/* width: 10%; */
	border-top-right-radius: 20px;
}

.table-responsive {
	/* min-height: 320px; */
	margin-bottom: 100px;
}




@media screen and (max-width:1120px) {
	.table-responsive {
		&.prueba {

			overflow: auto;

			th {
				border-bottom: none !important;
			}

			td {
				border-top: none !important;
			}


			table {
				table-layout: fixed;
				/* width:100%; */
			}

			td:last-child,
			th:last-child {
				position: sticky;
				right: 0;
				z-index: 1;
				background-color: #f0f0f0;
			}

			th:nth-child(1) {
				width: 200px;
			}

			th:nth-child(2) {
				width: 234px;
			}

			th:nth-child(3) {
				width: 233px;
			}

			th:nth-child(4) {
				width: 210px;
			}

			th:nth-child(4) {
				width: 200px;
			}

			th:nth-child(5) {
				width: 200px;
			}

			th:nth-child(6) {
				width: 200px;
			}

			th:last-child {
				width: 150px;
			}

			th:last-child {
				z-index: 2;
				box-shadow: -5px 0px 24px 0 rgba(101, 101, 101, 0.35);
				background-color: #C29982;
				position: sticky;
				top: 0;
			}
			th:last-child:after {
				position: absolute;
				background-color: #C29982;
				content: '';
    			top: 0;
    			right: 0px;
    			width: 20px;
    			height: 20px;
    			z-index: 1;
    			border-top-right-radius: 20px;
    			box-shadow: 0 -25px 0 0 #ffffff;
			}


		}
	}
}


.filter-btn {
	display: inline-flex;
	padding: 10px;
	text-align: center;
	background: $primary-color;
	font-size: 13px;
	border-radius: 6px;
	color: #ffffff;
	align-items: flex-start;
	justify-content: flex-end;
	margin-left: 5px;
	cursor: pointer;
}

.table-sticky {

	/* th,
	td,
	tr {
		border-bottom: none !important;
		border-top: none !important;
		border: none !important;
	} */
}

.tab-header {
	display: inline-flex;
	/* justify-content: space-between; */
	justify-content: center;
	align-items: center;
	width: 100%;
	text-align: center;
}

.new-pagination {

	.page-item {
		margin: 0 3.5px;

		.page-link {
			border: none !important;
			border-radius: 4px;
			background-color: #CED4DA;
			color: #ffffff !important;

		}

		&.active {

			.page-link {
				color: #fff !important;
				/* background-color: #045491 !important; */
			}

			a {
				color: #fff !important;
			}
		}

		.bg-blue {
			background-color: #ffffff;
			color: #8D95A4 !important;
			border: 1px solid #8D95A4!important;
		}
	}


}

.skeleton {
	animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
	0% {
		background-color: hsl(200, 20%, 80%);
	}

	100% {
		background-color: hsl(200, 20%, 95%);
	}
}

.skeleton-text {
	width: 100%;
	height: 0.7rem;
	margin-bottom: 0.5rem;
	border-radius: 0.25rem;
}

.skeleton-text__body {
	width: 75%;
}

.skeleton-footer {
	width: 30%;
}

.skeleton-badge {
	width: 100%;
	height: 2rem;
}

.skeleton-card {
	width: 100%;
	height: 15em;
}


// .table-responsive {
//     min-height: 320px;
//   }
//   @media screen and (max-width: 1120px) {
//     .table-responsive.prueba {
//       overflow: auto;
//     }
//     .table-responsive.prueba th {
//       border-bottom: none !important;
//     }
//     .table-responsive.prueba td {
//       border-top: none !important;
//     }
//     .table-responsive.prueba table {
//       table-layout: fixed;
//       /* width:100%; */
//     }
//     .table-responsive.prueba td:last-child, .table-responsive.prueba th:last-child {
//       position: sticky;
//       right: 0;
//       z-index: 1;
//       background-color: #e6eff3;
//     }
//     .table-responsive.prueba th:nth-child(1) {
//       width: 200px;
//     }
//     .table-responsive.prueba th:nth-child(2) {
//       width: 234px;
//     }
//     .table-responsive.prueba th:nth-child(3) {
//       width: 233px;
//     }
//     .table-responsive.prueba th:nth-child(4) {
//       width: 210px;
//     }
//     .table-responsive.prueba th:nth-child(4) {
//       width: 200px;
//     }
//     .table-responsive.prueba th:nth-child(5) {
//       width: 200px;
//     }
//     .table-responsive.prueba th:nth-child(6) {
//       width: 200px;
//     }
//     .table-responsive.prueba th:last-child {
//       width: 150px;
//     }
//     .table-responsive.prueba th:last-child {
//       z-index: 2;
//       box-shadow: -5px 0px 24px 0 rgba(68, 102, 242, 0.35);
//       background-color: #e6eff3;
//       position: sticky;
//       top: 0;
//     }
//   }
//   .table-sticky th, .table-sticky td, .table-sticky tr {
//     border-bottom: none !important;
//     border-top: none !important;
//     border: none !important;
//   }

// .table-responsive {
//     min-height: 320px;
// }

// @media screen and (max-width:1120px) {
//     .table-responsive {
//         &.prueba {
//             overflow: auto;
//             th {
//                 border-bottom: none !important;
//             }

//             td {
//                 border-top: none !important;
//             }


//             table {
//                 table-layout: fixed;
//                 /* width:100%; */
//             }

//             td:last-child,
//             th:last-child {
//                 position: sticky;
//                 right: 0;
//                 z-index: 1;
//                 background-color: #e6eff3;
//             }

//             th:nth-child(1) {
//                 width: 200px;
//             }

//             th:nth-child(2) {
//                 width: 234px;
//             }

//             th:nth-child(3) {
//                 width: 233px;
//             }

//             th:nth-child(4) {
//                 width: 210px;
//             }

//             th:nth-child(4) {
//                 width: 200px;
//             }

//             th:nth-child(5) {
//                 width: 200px;
//             }

//             th:nth-child(6) {
//                 width: 200px;
//             }

//             th:last-child {
//                 width: 150px;
//             }

//             th:last-child {
//                 z-index: 2;
//                 box-shadow: -5px 0px 24px 0 rgba(68, 102, 242, 35%);
//                 background-color: #e6eff3;
//                 position: sticky;
//                 top: 0;
//             }


//         }
//     }
// }

// .table-sticky {
//     th,
//     td,
//     tr {
//         border-bottom: none !important;
//         border-top: none !important;
//         border: none !important;
//     }
// }