// @import "bootstrap.scss";
@import "~bootstrap-scss/bootstrap.scss";
@import "color.scss";
@import "themify.scss";
@import "whether-icon/whether-icon";
@import "flag-icon/flag-icon-base";
@import "flag-icon/flag-icon-more";
@import "flag-icon/flag-icon-list";
@import "icoicon/icons";
@import "font-awesome.scss";
@import "sticky/sticky";
@import "slick.scss";
@import "slick-theme.scss";
@import "~react-toastify/dist/ReactToastify.css";
@import "~@fullcalendar/common/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";

// Datepicker
@import "~react-datepicker/dist/react-datepicker.css";

//  ImageCrop
@import "~react-image-crop/dist/ReactCrop.css";

// Mde EDitor
@import "~easymde/dist/easymde.min.css";

// Gallery Light Box
@import "~react-image-lightbox/style.css";

// chat emoji
@import "~emoji-mart/css/emoji-mart.css";

@import "chartist/chartist-settings";
@import "chartist/chartist";

@import '@asseinfo/react-kanban/dist/styles.css';

@import "~react-perfect-scrollbar/dist/css/styles.css";

@import "tabla/tabla.scss";

body{
    .notie-container {
        z-index: 5000;
    }
}

.card-permisos{
    // background-color: aquamarine;
    padding: 1em 0.5em;
}