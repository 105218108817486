/**=====================
     02. Generic CSS Start
==========================**/

/*====== Padding css starts ======*/
$i: 0;

@while $i<=50 {
  .p-#{$i} {
    padding:#{$i}px;
  }

  $i: $i+5;
}

/*====== Padding css ends ======*/

/*====== Padding-left css starts ======*/
$i: 0;

@while $i<=50 {
  .p-l-#{$i} {
    padding-left:#{$i}px;
  }

  $i: $i+5;
}

/*====== Padding-left css ends ======*/

/*====== Padding-top css starts ======*/
$i: 0;

@while $i<=50 {
  .p-t-#{$i} {
    padding-top:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Padding-top css ends ======*/


/*====== Padding-bottom css starts ======*/
$i: 0;

@while $i<=50 {
  .p-b-#{$i} {
    padding-bottom:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Padding-bottom css ends ======*/

/*====== Padding-right css starts ======*/
$i: 0;

@while $i<=50 {
  .p-r-#{$i} {
    padding-right:#{$i}px;
  }

  $i: $i+5;
}

/*====== Padding-right css ends ======*/


/*====== Margin css starts ======*/
$i: 0;

@while $i<=50 {
  .m-#{$i} {
    margin:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin css ends ======*/

/*====== Margin-top css starts ======*/
$i: 0;

@while $i<=50 {
  .m-t-#{$i} {
    margin-top:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin-top css ends ======*/

/*====== Margin-Bottom css starts ======*/
$i: 0;

@while $i<=50 {
  .m-b-#{$i} {
    margin-bottom:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin-Bottom css ends ======*/

/*====== Margin-left css starts ======*/
$i: 0;

@while $i<=50 {
  .m-l-#{$i} {
    margin-left:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin-left css ends ======*/

/*====== Margin-right css starts ======*/
$i: 0;

@while $i<=50 {
  .m-r-#{$i} {
    margin-right:#{$i}px;
  }

  $i: $i+5;
}

/*====== Margin-right css ends ======*/

/*====== Border-radius css starts ======*/
$i: 0;

@while $i<=10 {
  .b-r-#{$i} {
    border-radius:#{$i}px !important;
  }

  $i: $i+1;
}

/*====== Border-radius css ends ======*/


/*====== Font-size css starts ======*/
$i: 12;

@while $i<=100 {
  .f-#{$i} {
    font-size:#{$i}px;
  }

  $i: $i+2;
}

/*====== Font-size css ends ======*/


/*====== Font-weight css starts ======*/
$i: 100,
  300,
  400,
  600,
  700,
  900;

@each $val in $i {
  .f-w-#{$val} {
    font-weight: $val;
  }
}

/*====== Font-weight css ends ======*/


/*====== Font-style css starts ======*/
$i: normal,
  italic,
  oblique,
  initial,
  inherit;

@each $val in $i {
  .f-s-#{$val} {
    font-style: $val;
  }
}

/*====== Font-style css ends ======*/


/*====== Text-Decoration css starts ======*/

$i: overline,
  line-through,
  underline,
  dashed,
  blink,
  dotted,
  initial,
  none,
  solid,
  wavy,
  inherit,
  double;

@each $val in $i {
  .text-#{$val} {
    text-decoration: $val;
  }
}

/*====== Text-Decoration css ends ======*/


/*====== Vertical-Align css starts ======*/
$i: baseline,
  sub,
  super,
  top,
  text-top,
  middle,
  bottom,
  text-bottom,
  initial,
  inherit;

@each $val in $i {
  .#{$val} {
    vertical-align: $val;
  }
}

/*====== Vertical-Align css ends ======*/


/*====== Position css starts ======*/

$i: static,
  absolute,
  fixed,
  relative,
  initial,
  inherit;

@each $val in $i {
  .p-#{$val} {
    position: $val;
  }
}

/*====== Position css ends ======*/

/*====== Float css starts ======*/
$i: left,
  right,
  none;

@each $val in $i {
  .f-#{$val} {
    float: $val;
  }
}

/*====== Float css ends ======*/


/*====== Overflow css starts ======*/
$i: hidden,
  visible,
  auto;

@each $val in $i {
  .o-#{$val} {
    overflow: $val;
  }
}

/*====== Overflow css ends ======*/


/*====== Image-sizes css starts ======*/
$i: 10;

@while $i<=100 {
  .img-#{$i} {
    width:#{$i}px !important;
  }

  $i: $i+10;
}

/*====== Image-sizes css ends ======*/

/*======= Text css starts ===========*/
$text-name: primary,
  secondary,
  success,
  danger,
  info,
  light,
  dark,
  warning;
$text-color : $primary-color ,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $text-name {
  $i: index($text-name, $var);

  .font-#{$var} {
    color: nth($text-color, $i) !important;
  }
}

/*======= Text css ends ===========*/


/*======= Label-color css starts  ======= */

.label {
  border-radius: 2px;
  color: $white;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

$label-name: theme,
  primary,
  secondary,
  success,
  danger,
  info,
  light,
  dark,
  warning;
$label-color: $primary-color ,
  $primary-color ,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $label-name {
  $i: index($label-name, $var);

  .label-#{$var} {
    background-color: nth($label-color, $i);
  }
}


/*======= Label-color css ends  ======= */


/*======= Badge-color css starts  ======= */

$badge-name: primary,
  secondary,
  success,
  danger,
  info,
  light,
  dark,
  warning;
$badge-color: $primary-color ,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $badge-name {
  $i: index($badge-name, $var);

  .badge-#{$var} {
    background-color: nth($badge-color, $i);
  }
}


/*======= Badge-color css end  ======= */


/*======= Background-color css starts  ======= */

$background-name: primary,
  secondary,
  success,
  danger,
  info,
  light,
  dark,
  warning;
$background-color: $primary-color ,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $background-name {
  $i: index($background-name, $var);

  .bg-#{$var} {
    background-color: nth($background-color, $i) !important;
    color: $white;
  }
}


/*======= Background-color css end  ======= */

/*======= Font-color css starts  ======= */

$text-name: primary,
  secondary,
  success,
  danger,
  info,
  light,
  dark,
  warning,
  google-plus,
  twitter,
  linkedin,
  fb;
$label-color: $primary-color ,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $light-color,
  $dark-color,
  $warning-color,
  $google-plus,
  $twitter,
  $github,
  $fb;

@each $var in $text-name {
  $i: index($text-name, $var);

  .txt-#{$var} {
    color: nth($label-color, $i) !important;
  }
}

.txt-white {
  color: $white !important;
}

/*======= Font-color css end  ======= */


/*======= Button-color css starts  ======= */
// btn--color//
@each $btn-name, $btn-color in (primary, $primary-color ),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-#{$btn-name} {
    background-color: $btn-color !important;
    border-color: $btn-color !important;
    /* --width: 220px; */
    min-width: 80px!important;
    --height: 40px;
    border: 0;
    position: relative;
    min-width: var(--width);
    min-height: var(--height);
    border-radius: var(--height);
    color: #fff;
    background: #f22c3a;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 0 1rem; */

    @if($btn-name =="light") {
      color: $dark-color !important;
    }

    &.disabled {
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }

    &:disabled {
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }

   /*  &:nth-child(1) {
      --btn-bg: #da0000;
      --bg: #f22c3a;
    } */

    & .icon-container {
      --icon-size: 25px;
      position: relative;
      width: var(--icon-size);
      height: var(--icon-size);
      margin-left: 15px;
      transition: transform 500ms ease;
    }

    &.icon-container .icon {
      position: absolute;
      left: 0;
      top: 0;
      width: var(--icon-size);
      height: var(--icon-size);
      transition: transform 500ms ease, opacity 250ms ease;
    }

    &.icon-container .icon.icon-left {
      transform: translateX(-200%);
      opacity: 0;
    }

    & .icon-container .icon svg {
      width: 100%;
      height: 100%;
      fill: white;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--btn-bg);
      border-radius: var(--height);
      z-index: 1;
      transition: transform 500ms ease;
    }

    &:hover::after {
      transform: translateX(65%);
    }

    &:hover .icon-container {
      transform: translateX(125%);
    }

    &:hover .icon-container .icon-left {
      transform: translateX(0);
      opacity: 1;
    }

    &:hover .icon-container .icon-right {
      transform: translateX(200%);
      opacity: 0;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($btn-color, 30%);
    }
  }
}

/*======= Button-color css ends  ======= */
@each $btn-name, $btn-color in (primary, $primary-color ),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-outline-#{$btn-name}-2x {
    border-width: 2px;
    border-color: $btn-color;
    color: $btn-color;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
      box-shadow: none;
    }
  }
}

// outline buttons//
@each $btn-name, $btn-color in (primary, $primary-color ),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-outline-#{$btn-name} {
    border-color: $btn-color;
    color: $btn-color;
    background-color: transparent;

    &.disabled {
      color: $btn-color;
    }

    @if($btn-name =="light") {
      color: $dark-color;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }
  }
}

//active button css //

@each $btn-name, $btn-color in (primary, $primary-color ),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-#{$btn-name}:not([disabled]):not(.disabled).active {
    background-color: darken($btn-color, 10%);
    border-color: darken($btn-color, 10%);
    box-shadow: none !important;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%);
      border-color: darken($btn-color, 10%);
    }
  }
}

//* active button css end *//

@each $btn-name, $btn-color in (primary, $primary-color ),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {

  .btn-outline-#{$btn-name}-2x:not([disabled]):not(.disabled).active {
    background-color: $btn-color;
    border-color: $btn-color;
    box-shadow: none !important;
    color: white;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      box-shadow: none !important;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }
  }
}

/*======= Table-Border-Bottom-color css starts  ======= */

$border-name: primary,
  secondary,
  success,
  danger,
  info,
  light,
  dark,
  warning;
$border-color: $primary-color ,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $border-name {
  $i: index($border-name, $var);

  table thead .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} td {
    border-bottom: 1px solid nth($border-color, $i);
  }
}


/*======= Table-Border-Bottom-color css ends  ======= */


/*======= Table styling css starts  ======= */

$table-name: primary,
  secondary,
  success,
  danger,
  info,
  light,
  dark,
  warning;
$table-color: $primary-color ,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $table-name {
  $i: index($table-name, $var);

  .table-styling .table-#{$var},
  .table-styling.table-#{$var} {
    background-color: nth($border-color, $i);
    color: $white;
    border: 3px solid nth($border-color, $i);

    thead {
      background-color: darken(nth($border-color, $i), 10%);
      border: 3px solid darken(nth($border-color, $i), 10%);
    }
  }
}


/*======= Table styling css ends  ======= */




/*======= All-Borders-color css starts  ======= */

$b-name: primary,
  secondary,
  success,
  danger,
  info,
  light,
  dark,
  warning;
$b-color: $primary-color ,
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $light-color,
  $dark-color,
  $warning-color;

@each $var in $b-name {
  $i: index($b-name, $var);

  .b-#{$var} {
    border: 1px solid nth($b-color, $i) !important;
  }

  .b-t-#{$var} {
    border-top: 1px solid nth($b-color, $i) !important;
  }

  .b-b-#{$var} {
    border-bottom: 1px solid nth($b-color, $i) !important;
  }

  .b-l-#{$var} {
    border-left: 1px solid nth($b-color, $i) !important;
  }

  .b-r-#{$var} {
    border-right: 1px solid nth($b-color, $i) !important;
  }
}


/*======= All-Borders-color css ends  ======= */


/*====== Border width css starts ======*/
$i: 1;

@while $i<=10 {
  .border-#{$i} {
    border-width:#{$i}px !important;
  }

  $i: $i+1;
}

/*====== Border width css ends ======*/

.opacity-0 {
  opacity: 0;
}

.shadow-0 {
  box-shadow: none;
}

.map-block {
  height: 300px;
  width: 100%;
}

.map-js-height {
  .map-block {
    position: relative;
    overflow: hidden;

  }
}

.map-block {

  .jvectormap-container {
    svg {
      height: 300px;
    }
  }
}

.leaflet-container {
  height: 400px;
  width: 100%;
}

/**====== custom scrollbar css start ======**/
.digits {
  font-family: $font-work-sans, $font-serif;
}

.custom-scrollbar {
  transition: all 3s;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-gray;
  }

  &::-webkit-scrollbar {
    width: 3px;
    transition: .3s;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($primary-color , 0.20);
  }
}


/**====== Custom scrollbar css end ======**/

/**====== Animation css Start ======**/
.comment {
  color: #9f9ba5;
  font-style: italic;
}

.line {
  color: $dark-color;
}

.line {
  pre {
    font-size: 100%;
  }
}

/**====== Animation css end ======**/

/**====== list style css Start ======**/
.list-circle {
  list-style: circle;
}

/**====== list style css end ======**/

/**====== Modal style css Start ======**/
.theme-close {
  opacity: 1;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 1;
  right: 0;
  background-color: $white !important;
  border-radius: 5px;
}

/**====== Modal style css end ======**/

/**====== Animation css start ======**/
.options {
  >div {
    color: $gray-60;
    display: inline-block;
    padding: 2px 10px;
    border: 1px solid;
    margin: 0 8px 8px 0;
    transition: all 0.3s ease;

    &:hover {
      background-color: $primary-color ;
      color: $white;
      transition: all 0.3s ease;
    }
  }
}

/**====== Animation css Ends ======**/

.modal-footer {
  flex-wrap: wrap;
}

.img-cropper {
  #putData {
    margin-bottom: 0;
  }

  .img-container {
    min-height: auto;
    margin-bottom: 0;
  }

  .docs-data {
    >.input-group {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .docs-preview {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.bootstrap-datetimepicker-widget {
  font-family: $font-work-sans, $font-serif;
}

.dropzone {
  .dz-preview {
    .dz-error-message {
      background: $white !important;
      color: $primary-color !important;
      border: 1px solid $primary-color ;

      &:after {
        border-bottom: 6px solid $primary-color !important;
      }
    }
  }
}

.typeahead {
  .theme-form {
    .form-group {
      margin-bottom: 0;
    }
  }
}

.datepicker {
  z-index: 99;
}

.irs-from,
.irs-to,
.irs-single {
  background: $primary-color ;
}

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge {
  background-color: $primary-color ;
}

/**=====================
     02. Generic CSS Ends
==========================**/


.btn-container {
	/* width: 100%;
	height: 100%; */
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--bg);
  /* margin-right: auto; */
  margin-left: auto;

	/* &:nth-child(1) {
		--btn-bg: #B80000;
	}
	&:nth-child(2) {
		--btn-bg: #fac300;
		--bg: #fc6100;
	} */
}

.btn-nuevo {
  --width: 180px;
  --height: 40px;
  border: 0;
  position: relative;
  min-width: var(--width);
  min-height: var(--height);
  border-radius: var(--height);
  color: #fff;
  font-family: sans-serif; /* Reemplaza $font-roboto si no está definido */
  font-weight: 400;
  background: #B80000; /* Color inicial */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Cambio de color al hacer hover */
.btn-nuevo:hover {
  background-color: #7E0000; /* Nuevo color */
  color: #fff;
}

/* Elimina animaciones previas */
.btn-nuevo::after,
.btn-nuevo .icon-container,
.btn-nuevo .icon {
  transition: none !important; /* Elimina cualquier transición */
  transform: none !important; /* Evita cambios en posición */
  opacity: 1 !important;
}

/* Opcional: Asegurar que los iconos no se desplacen */
.btn-nuevo .icon-container {
  position: static;
}


@media (max-width: 1208px) {
  .btn-container {
    margin-left: auto;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .btn-container {
    margin-left: 0;
    margin-right: auto;
  }
  .btn-container button {
    width: 100%;
  }
}
@media (max-width: 475px) {
  .btn-container {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

.form-control {
  border-radius: 100px!important;
  border: 1px solid rgba(154, 85, 46, .6);
  background-color: #F6FCFF;
  font-family: $font-roboto!important;
  font-size: 14px!important;
  color: #9A552E!important;
  padding-left: 30px;
}
.form-control::placeholder {
  font-family: $font-roboto!important;
  font-size: 14px!important;
  color: #9A552E!important;
}
.form-control:focus {
  background-color: transparent;
}

.input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.inputnombre::after {
  position: absolute;
  content: '\f007';
  left: 10px;
  font-family: $font-awesome;
  font-size: 14px;
  color: #FF8C00;
}
.inputarticulo::after {
  position: absolute;
  content: '\f0f5';
  left: 10px;
  font-family: $font-awesome;
  font-size: 14px;
  color: #FF8C00;
}
.inputbook::after {
  position: absolute;
  content: '\f02d';
  left: 10px;
  font-family: $font-awesome;
  font-size: 14px;
  color: #FF8C00;
}
.inputcorreo::after {
  position: absolute;
  content: '\f0e0';
  left: 10px;
  font-family: $font-awesome;
  font-size: 14px;
  color: #FF8C00;
}
.inputtelefono::after {
  position: absolute;
  content: '\f095';
  left: 10px;
  font-family: $font-awesome;
  font-size: 14px;
  color: #FF8C00;
}
.inputfile::after {
  position: absolute;
  content: '\f15b';
  left: 10px;
  font-family: $font-awesome;
  font-size: 14px;
  color: #FF8C00;
}
.inputubicacion::after {
  position: absolute;
  content: '\f041';
  left: 10px;
  font-family: $font-awesome;
  font-size: 14px;
  color: #FF8C00;
}
.inputnumero::after {
  position: absolute;
  content: '\f292';
  left: 10px;
  font-family: $font-awesome;
  font-size: 14px;
  color: #FF8C00;
}
.inputmin::after {
  position: absolute;
  content: '\f160';
  left: 10px;
  font-family: $font-awesome;
  font-size: 14px;
  color: #FF8C00;
}
.inputmax::after {
  position: absolute;
  content: '\f161';
  left: 10px;
  font-family: $font-awesome;
  font-size: 14px;
  color: #FF8C00;
}
.inputtext::after {
  position: absolute;
  content: '\f036';
  left: 10px;
  font-family: $font-awesome;
  font-size: 14px;
  color: #FF8C00;
}

.input-time{
  text-align: start;
  padding: 6px 15px;
  width: 100%;
  max-width: calc(16px * 7);
  height: 50px;
}

.card-body label {
  /* font-family: $font-roboto;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
  padding-left: 14px; */
  display: none;
}
.card-body .labelupload {
  display: block;
  position: relative;
}
.card-body .dzu-inputLabel {
  display: flex!important;
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-align: center;
  font-family: $font-roboto;
  margin-bottom: 0;
}

.btn-regresar {
  position: relative;
  background-color: rgba(154, 85, 46, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 30px;
  height: 30px; */
  border: none;
  padding: 10px 24px 10px 32px;
  color: #ffffff;
  border-radius: 100px;
}

.btn-regresar::after {
  position: absolute;
  content: '\f053';
  /* width: 100%; */
  left: 12px;
  height: 100%;
  font-family: $font-awesome;
  font-size: 18px;
  color: #ffffff;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .5s ease-in-out;
}
.btn-regresar::after:hover {
  color: rgba(154, 85, 46, 1);
}

.card-header h4 {
  font-family: $font-roboto;
  font-weight: 500;
  color: #9A552E;
  margin-bottom: 0;
}

.card-body h6 {
  font-family: $font-roboto;
  font-weight: 400;
  color: #9A552E;
}

.card-body .text-danger {
  font-family: $font-roboto!important;
  font-weight: 200!important;
  font-size: 12px!important;
}

.page-sidebar .logo-wrapper img {
  width: 95px;
}


.input-field {
  position: relative;
}

.input-field input {
  width: 100%;
  height: 50px;
  border-radius: 100px;
  padding: 6px 15px;
  border: 2px solid #fff;
  background: transparent;
  outline: none;
  border: 1px solid rgba(154, 85, 46, 0.6)!important;
  /* background-color: #F6FCFF; */
  font-family: "Roboto Condensed" !important;
  font-size: 14px !important;
  color: #5d5d5d !important;
}

.input-field textarea {
  width: 100%;
  border-radius: 10px;
  height: 120px;
  padding: 16px 15px;
  border: 1px solid rgba(154, 85, 46, 0.6)!important;
  font-family: "Roboto Condensed" !important;
  font-size: 14px !important;
  color: #5d5d5d !important;
  background: transparent;
  outline: none;
}

.input-field label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  font-size: 14px;
  pointer-events: none;
  transition: 0.3s;
  display: block!important;
  color: #9A552E !important;
}

.input-field {
  label{
    i {
      color: #FF8C00;;
    }
    span {
      color: #FF8C00;;
    }
  }
}

input:focus {
  border: 2px solid #18ffff;
}

input:focus~label,
input:valid~label {
  top: 0;
  left: 15px;
  font-size: 14px;
  padding: 0 4px;
  background: #ffffff;
}

select:focus~label,
select:valid~label {
  top: 0;
  left: 15px;
  font-size: 14px;
  padding: 0 4px;
  background: #ffffff;
}

textarea:focus~label,
textarea:valid~label {
  top: 0;
  left: 15px;
  font-size: 14px;
  padding: 0 4px;
  background: #ffffff;
}

/* .dzu-input {
  display: block!important;
} */

/* select {
  position: relative;
}
select::before {
  position: absolute;
  right: 10px;
  margin: auto;
  content: '\f078';
  font-family: $font-awesome;
  font-size: 20px;
} */

.input-field.select {
  select {
    color: #ffffff!important;
    background-color: transparent;
    border: none;
    padding-left: 6px!important;
    position: relative;
  }
}
.input-field.select {
  select:focus, select:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
  }
} 

.input-field.select {
  width: 100%;
  height: 50px;
  border-radius: 100px;
  padding: 6px 15px;
  border: 2px solid #fff;
  background: transparent;
  outline: none;
  border: 1px solid rgba(154, 85, 46, 0.6)!important;
  /* background-color: #F6FCFF; */
  font-family: "Roboto Condensed" !important;
  font-size: 14px !important;
  color: #5d5d5d !important;
  margin: 0;
  display: flex;
  position: relative;
  align-items: center;
}

.input-field.select::after {
  position: absolute;
    right: 10px;
    margin: auto;
    content: '\f078';
    font-family: $font-awesome;
    font-size: 14px;
    color: #FF8C00;
}

.input-field.select {
  select:focus, select:focus-visible, select:target, select:checked, select:valid {
    color: #373737!important;
  }
  .form-select {
    --bs-form-select-bg-img: unset;
  }
}

.selectnumeric {
  width: 100%;
  height: 100%;

  .css-13cymwt-control {
    background-color: transparent;
    border-radius: 100px;
    border: 1px solid rgba(154, 85, 46, 0.6)!important;
    height: 100%;
  }
  .css-t3ipsp-control {
    background-color: transparent;
    border-radius: 100px;
    border: 1px solid rgba(154, 85, 46, 0.6)!important;
    height: 100%;
  }

  #react-select-2-listbox {
    min-height: 150px;
    margin-top: 0;
  }

  .css-1xc3v61-indicatorContainer {
    color: #FF8C00!important;
  }
  .css-15lz6c-indicatorContainer {
    color: #FF8C00!important;
  }

  /* div:last-child {
    background-color: transparent;
  } */
}

.btn-nuevo:hover {
  .icon-container.password {
    transform: translateX(74%);
  }
  .icon-container.medium {
    transform: translateX(140%);
  }
}


/* ====== ALERTAS ====== */

/* body {
  .notie-container {
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }
} */

.notie-background-info {
  background-color: #9A552E!important;
}
.notie-background-error {
  background-color: #B80000!important;
}
.notie-background-success {
  background-color: #22af47 !important;
}



@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?eykqdr');
  src:  url('fonts/icomoon.eot?eykqdr#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?eykqdr') format('truetype'),
    url('fonts/icomoon.woff?eykqdr') format('woff'),
    url('fonts/icomoon.svg?eykqdr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icono_Almacen:before {
  content: "\e900";
}
.icon-icono_Articulos-de-limpieza:before {
  content: "\e901";
}
.icon-icono_Bebidas:before {
  content: "\e902";
}
.icon-icono_Billetes:before {
  content: "\e903";
}
.icon-icono_Checklist:before {
  content: "\e904";
}
.icon-icono_Cliente:before {
  content: "\e905";
}
.icon-icono_Combos:before {
  content: "\e906";
}
.icon-icono_Complementos:before {
  content: "\e907";
}
.icon-icono_Contrasena:before {
  content: "\e908";
}
.icon-icono_Corte-de-caja:before {
  content: "\e909";
}
.icon-icono_Ensaladas:before {
  content: "\e90a";
}
.icon-icono_Factura:before {
  content: "\e90b";
}
.icon-icono_Fondo-de-caja:before {
  content: "\e90c";
}
.icon-icono_Ingredientes:before {
  content: "\e90d";
}
.icon-icono_Inventario:before {
  content: "\e90e";
}
.icon-icono_Litro:before {
  content: "\e90f";
}
.icon-icono_Materia-Prima:before {
  content: "\e910";
}
.icon-icono_Monedas:before {
  content: "\e911";
}
.icon-icono_Motocicleta:before {
  content: "\e912";
}
.icon-icono_Orden-de-compra:before {
  content: "\e913";
}
.icon-icono_Paquete:before {
  content: "\e914";
}
.icon-icono_Pollo:before {
  content: "\e915";
}
.icon-icono_Produccion:before {
  content: "\e916";
}
.icon-icono_Proveedor:before {
  content: "\e917";
}
.icon-icono_Pure:before {
  content: "\e918";
}
.icon-icono_Recetas:before {
  content: "\e919";
}
.icon-icono_Requisicion:before {
  content: "\e91a";
}
.icon-icono_Salsa:before {
  content: "\e91b";
}
.icon-icono_Terjeta-de-credito:before {
  content: "\e91c";
}
.icon-icono_Terjeta-de-debito:before {
  content: "\e91d";
}
.icon-icono_Terminal:before {
  content: "\e91e";
}
.icon-icono_Ticket:before {
  content: "\e91f";
}
.icon-icono_Tienda:before {
  content: "\e920";
}
.icon-icono_TPV-punto-de-venta:before {
  content: "\e921";
}
.icon-icono_Usuario-interno:before {
  content: "\e922";
}
.icon-icono_Verduras:before {
  content: "\e923";
}
